<template>
	<!-- 积分购首页 -->
	<div :class="themeClass">
		<div>
			<!-- tab背景 -->
			<div style="width:100%;">
				<div>
					<img :src="imgUrl" width="100%" style="vertical-align: middle;" />
				</div>
			</div>
			<!-- 没数据时 -->
			<div v-if="showImge" v-loading="loadingShop" >
				<!-- <img width="100%" src="../../assets/pointImg.png" /> -->
				<div style="margin:20px auto;width: 1100px;height:377px;background-color:#f5fafe;">
					<div style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;">
						<div>
							<img src="../../assets/nogoods.png" width="100%">
							<div
								style="width: 103;font-size: 30px;line-height: 80px;font-weight: bold;color: #1144ab;text-align: center;letter-spacing: 5px;padding:0 10px;">
								活动正在筹划中...</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 没数据时 -->

			<!-- tab背景 -->
			<div v-loading="loadingShop">
			<div  class="pointcontent" v-if="TabList.length !=0">
				<div class="flex-row-align-center shop" style="width:1100px;">
					<div v-for="(item,indexs) in TabList" :key="indexs" @click="tabClick(item,indexs)"
						:class="tabId == item.FShopUnitID ?  'shopTabActive' : 'shopTab' " class="">
						<div style="width: 77%;margin: auto;">
							<div class="font-size16 hide-text">{{ item.FShopName}}</div>
						</div>
					</div>
				</div>
				<!-- 店铺 -->
				<div class="flex-row-align-center padding-t-15 padding-b-5 shopBox" v-if="showShop">
					<div class="flex-row-wrap" style="padding: 0px 10px;">
						<div v-for="(item, indexItem) in hotShop" :key="indexItem" @click="shopClick(item,indexItem)"
							class=" bg-fff shopItem  flex-row-space-between-center margin-b-10 "
							:class="ShowshopId ==item.FShopUnitID ?'colorActive':''" style="justify-content: unset;">
							<img class="margin-r-10 shopItemImg" :src="item.FShopLogoPath" />
							<div class="flex-colum-between">
								<el-tooltip :content="item.FShopName" placement="top">
									<div class="shopItemTitle hide-text font-size13 font-weight700">
										{{ item.FShopName}}
									</div>
								</el-tooltip>
								<el-tooltip :content="item.FRemark " placement="top">
									<div class="text-el font-size12 margin-t-10 margin-b-5">
										{{ item.FRemark}}
									</div>
								</el-tooltip>
							</div>
						</div>
					</div>
				</div>
				<div class="shopBoxBottom margin-b-15" v-if="hotShop.length >4">
					<div @click="clickShowShop">
						<span class="el-icon-s-shop iconShop"></span>
						<span style="line-height: 30px;font-size: 14px;">{{showShoptext}}</span>
					</div>
				</div>
				<!-- 店铺 -->
				<!-- 模糊搜索 -->
				<div class="searchBox padding-tb-15 flex-row-space-between">
					<div style="line-height: 35px;" class="integral flex-row-space-between margin-r-20">
						<div>
							<span class="colorff0000 font-size18 font-weight700 margin-r-10">JIFENGOU</span>
							<span class="colorff0000 font-size18 font-weight700">/</span>
							<span class="font-size18 font-weight700  margin-l-10">积分购</span>
						</div>
						<span>
							当前店铺积分：<span class="color-theme">{{fEndBalance.toLocaleString()}}</span>积分
						</span>
					</div>

					<div>
						<el-input placeholder="请输入商品模糊查询" class="output"
							style="font-size: 15px;text-align: left;line-height: 13px;" v-model="search"
							@keyup.native.enter="searchClick" clearable @clear="searchClear">
							<el-button slot="append" @click="searchClick"
								style="color: #ffffff;font-size: 15px;padding: 12px 20px;">搜索</el-button>
						</el-input>
					</div>
				</div>
				<!-- 模糊搜搜 -->
				<!-- 可兑换商品 -->
				<div class="flex-row-space-between">
					<div v-if="fIsExchange == 0" @click="ExcClick(0)"
						class="goodsTab-box text-center font-size13 pointer-color-theme  margin-tb-10">
						看可兑换商品
						<span class="font-color-999 font-size16 font-weight700 iconfont icon-xuanzhibeifen"
							style="color: #99a29d;">
						</span>
					</div>
					<div v-if="fIsExchange == 1" @click="ExcClick(1)"
						class="goodsTab-box text-center font-size13 pointer-color-theme margin-tb-10"
						:class="fIsExchange == 1 ? 'textfon' : 'border-r-F2F2F2'">
						看可兑换商品
						<span class="font-color-999 font-size16 font-weight700 iconfont icon-xuanzhibeifen"
							:class="fIsExchange == 1 ? 'color-theme' : ''">
						</span>
					</div>
					<div class="flex-row margin-tb-auto">
						<div>{{ total }}&nbsp;件商品</div>
						<div class="el-icon-arrow-left margin-tb-auto margin-rl-10 pointer-color-theme"
							@click="pageChange('-')"></div>
						<div>
							<span class="color-theme margin-r-5">{{ currentPage }}</span>
							<span>/</span>
							<span class="margin-l-5">{{ Math.ceil(total / pagesize) }}</span>
						</div>
						<div class="el-icon-arrow-right margin-tb-auto margin-rl-10 pointer-color-theme"
							@click="pageChange('+')"></div>
					</div>
				</div>
				<!-- 可兑换商品 -->
				<!-- 积分购 -->
				<div v-loading="pointLoading">
					<div v-if="gePointlList.length != 0 ">
						<!-- ===================================================== -->
						<div class="">
							<div class="box-four-item-box flex-row-wrap width-fill">
								<div@click="gotoPointBuyGoodsBillDetails(item)"
									class="box-four-item flex-column-start border-F2F2F2 margin-b-10"
									:class="{ 'margin-r-10': (index + 1) % 5 != 0 }" v-for="(item, index) in gePointlList"
									:key="index">
									<!-- 列表图片 -->
									<div class="box-four-picture-box flex-row-center-center pointer position-relative">
										<img class="box-four-picture" style="width: 210px;height: 210px;":src="item.fGoods.fPicturePath" />
									</div>
									<!-- 列表内容 -->
									<div class="flex-colum-between padding-5" >
										<div class="box-two-title twoline-show font-size13">{{ item.fGoods.fGoodsFullName }}
										</div>
										<div class="flex-row-space-between margin-t-3 margin-b-5" >
										<div class="box-two-integral font-size16 font-weight700 font-color-FF0000" >
											{{ item.fGoods.fPointPrice }}
											<span class="font-size12">积分/{{ item.fGoods.fUnitName }}</span>
										</div>
										<div style="width: 40%;height:20px;line-height: 30px;color:#3C4373;" class="font-size12   text-right">可兑{{item.fGoods.fSalesVolume}}吨</div>
									 </div>
									</div>
								</div>
							</div>
						</div>
						<div class="pagination margin-b-20" v-if="gePointlList.length !=0">
							<pagination :currentPage="currentPage" :pagesize="pagesize" :total="total"
								@handleCurrentChange="handleCurrentChange"></pagination>
						</div>
					</div>
					<div v-if="gePointlList.length == 0" class="not-found-box  maink width-fill flex-row-align-center">
						<div class="not-found-innerbox flex-row-align-center font-size14 font-color-666">
							<span class="iconfont icon-tishi3 font-size14 font-color-FF0000"></span>
							<span class="margin-l-10">未</span>
							<span v-if="search !='' && gePointlList.length == 0">在<span
									class="font-color-FF0000">“{{ search }}”</span></span>
							<span class="">找到符合条件的相关商品</span>
						</div>
					</div>
				</div>
				<!-- 积分购 -->
			</div>
		 </div>
		</div>
		<div>

		</div>
	</div>
</template>
<script>
	import {
		mapGetters
	} from "vuex";
	import countDowm from '@/components/countDown/countDown.vue';
	import pagination from "@/components/pagination/pagination";
	export default {
		components: {
			countDowm,
			pagination
		},
		data() {
			return {
				showImge: false,
				imgUrl: require('@/assets/pointImg.png'),
				showShop: false,
				showShoptext: "收起店铺",
				TabList: [],
				hotShop: [], //接收店铺
				tabId: 5,
				ShowshopId: 5, //控制店铺选中效果的id
				loadingShop: false, //店铺的加载动画
				fEndBalance: 0, //积分
				search: "", //商品的模糊查询
				fIsExchange: 0, //可兑换商品
				gePointlList: [], //积分购
				pointLoading: false, //积分购的加载动画
				pointcountDownTime: 0, // 积分购
				isPointStartTime: false, //积分购
				fShopUnitID: -1, //店铺id
				// 当前页数
				currentPage: 1,
				// 每页条数
				pagesize: 15,
				// 总条数
				total: 0,
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			this.getHotShop(); //获取店铺

		},
		watch: {
			currentPage: function(val) {
				this.getPointBuyGoods();
			 },
			},
		methods: {
			//换页、
			pageChange(type) {
				if (type == '+') {
					if (this.currentPage < Math.ceil(this.total / this.pagesize)) {
						this.currentPage++;
					}
				} else {
					if (this.currentPage > 1) {
						this.currentPage--;
					}
				}
			},
			// tab点击事件
			tabClick(item, i) {
				console.log(item)
				this.currentPage = 1;
				this.fShopUnitID = item.FShopUnitID;
				this.fEndBalance = item.FEndBalance
				this.tabId = item.FShopUnitID;
				this.ShowshopId=item.FShopUnitID;
				this.getPointBuyGoods();
			},
			// 点击店铺展开和关闭的按钮
			clickShowShop() {
				this.showShop = !this.showShop
				if (this.showShop) {
					this.showShoptext = '收起店铺'
					this.showShop = true;
				} else {
					this.showShoptext = '看其他店铺';
					this.showShop = false;


				}
			},
			// 模糊搜索按清楚按钮时触发
			searchClear() {
				this.getPointBuyGoods()
			},
			//点击搜索按钮
			searchClick() {
				this.getPointBuyGoods()
			},
			// 点击可兑换
			ExcClick(val) {
				this.fIsExchange == 0 ? this.fIsExchange = 1 : this.fIsExchange = 0;
				this.getPointBuyGoods()
			},
			//点击店铺
			shopClick(obj, i) {
				console.log(obj)
				this.currentPage = 1;
				this.ShowshopId = obj.FShopUnitID;
				this.fShopUnitID = obj.FShopUnitID;

				//如果第一个是聚材自营店，则从第二个开始添加
				console.log(this.TabList[0].FShopUnitID)
				if (this.TabList[0].FShopUnitID == "5") {
					if (obj.FShopUnitID == "5") {
						this.tabId = obj.FShopUnitID;
					} else {
						let tabIndex = this.TabList.findIndex((value, keys, arr) => {
							return value.FShopUnitID == obj.FShopUnitID;
						})
						console.log(tabIndex)
						if (tabIndex == -1) {
							this.TabList.splice(1, 0, obj);
							this.tabId = obj.FShopUnitID;
						} else {
							this.TabList.splice(tabIndex, 1)
							console.log(this.TabList)
							this.TabList.splice(1, 0, obj);
							console.log(this.TabList)
							this.tabId = obj.FShopUnitID;
						}
						console.log(this.TabList.length)
						if (this.TabList.length > 4) {
							this.TabList = this.TabList.slice(0, 4);
						} else if (this.TabList.length <= 4) {
							this.TabList = this.TabList.slice(0, this.TabList.length);
						}

					}
					this.showShop = false;
					this.showShoptext = '看其他店铺';
				} else {
					let tabIndex2 = this.TabList.findIndex((value, keys, arr) => {
						return value.FShopUnitID == obj.FShopUnitID;
					})
					console.log(tabIndex2)
					if (tabIndex2 == -1) {
						this.TabList.splice(1, 0, obj);
						this.tabId = obj.FShopUnitID;
					} else {
						this.TabList.splice(tabIndex2, 1)
						console.log(this.TabList)
						this.TabList.splice(0, 0, obj);
						console.log(this.TabList)
						this.tabId = obj.FShopUnitID;

					}
					console.log(this.TabList.length)
					if (this.TabList.length > 4) {
						this.TabList = this.TabList.slice(0, 4);
					} else if (this.TabList.length <= 4) {
						this.TabList = this.TabList.slice(0, this.TabList.length);
					}
					this.showShop = false;
					this.showShoptext = '看其他店铺';

				}
				this.getPointBuyGoods();

			},
			// 重组列表  返回以每 num 个为一组的数据  如果其他页面用的多可写入public.js
			makeListForNum(list, num) {
				// console.log(list);
				// console.log('获取初始数据')
				let returnLIst = [];
				for (var i = 0; i < list.length; i += num) {
					returnLIst.push(list.slice(i, i + num));
				}
				// console.log(returnLIst);
				// console.log('获取整理后的数组。')
				return returnLIst;
			},
			// 积分购跳商品详情
			gotoPointBuyGoodsBillDetails(item) {
				console.log(item, '梁蕊')
				this.$router.push({
					path: '/Goods/PointBuyGoodsBillDetails',
					query: {
						fGoodsID: item.fGoods.fGoodsID,
						fGoodsPriceID: item.fGoods.fGoodsPriceID,
					}
				})
			},
			// 当前页 改变时会触发
			handleCurrentChange(Current) {
				this.currentPage = Current;
				this.getPointBuyGoods();
			},
			//获取商品
			getPointBuyGoods() {
				this.pointLoading = true;
				this.ApiRequestPost('/api/mall/ebactivity/point-buy-goods-price/get-point-buy-goods-by-fshop-id', {
						fShopUnitID: this.fShopUnitID,
						adcode: sessionStorage.getItem("ipAdcode"),
						city: "",
						flng: 0,
						flat: 0,
						skipCount: (this.currentPage - 1) * this.pagesize,
						maxResultCount: this.pagesize,
						filter: this.search,
						sorting: "",
						fwhether: this.fIsExchange
					})
					.then(res => {
							this.pointLoading = false;
							this.gePointlList = res.obj.dto.items;
							this.total = res.obj.dto.totalCount;
						},
						error => {
							this.pointLoading = false;
						});
			},

			// 获取推荐店铺数据
			getHotShop() {
				this.loadingShop = true;
				this.ApiRequestPostNOMess('api/mall/ebactivity/point-buy-goods-bill/sel-point-bu-shop', {}).then(res => {
						this.loadingShop = false;
						let arr = JSON.parse(res.obj)
						console.log(arr)
						// let obj1={
						// 	FRemark: '撒大大回家看看就好了就建立了考虑',
						// 	FShopLogoPath:'http://www.henghesoft.com:8444/api/henghe/blob/f753231a78f82f0f3b1239fb829cd230.png',
						// 	FShopName:"聚材自营店", 

						// }

						// arr.push(obj1)
						if (arr.length != 0) {
							this.hotShop = arr;
			
							this.showImge = false
					
							if (this.hotShop.length > 4) {
								this.showShop = true
							}
							this.TabList = this.hotShop.slice(0, 4);
							this.tabId = this.TabList[0].FShopUnitID;
							this.ShowshopId=this.hotShop[0].FShopUnitID;
							this.fShopUnitID = this.hotShop[0].FShopUnitID;
							this.fEndBalance = this.hotShop[0].FEndBalance;
							this.getPointBuyGoods(); //获取积分购列表数据
						}else{
							this.showImge = true
						}
					})
					.catch(err => {
						this.loadingShop = false;
					});
			},
		},
	};
</script>
<style scoped lang="scss">
	.topImg {
		height: 220px;
		width: 100%;
	}

	.shopTabActive {
		width: 275px;
		height: 53px;
		background-image: linear-gradient(to right, #ffedd4, #feddb2);
		clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 100% 100%, 0% 100%);
		text-align: center;
		line-height: 53px;
		color: #583a0e;
		font-weight: 600;
	}

	.shopTab {
		width: 275px;
		height: 53px;
		background-image: linear-gradient(to right, #3a406d, #2f3453);
		clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 100% 100%, 0% 100%);
		text-align: center;
		line-height: 53px;
		color: #FFCEBF;
		// border: 1px solid yellow;
	}

	.pointcontent {
		min-height: 550px;
		width: 1100px;
		height: auto;
		margin: 0 auto;
		// overflow: hidden;
		position: relative;

		.shop {
			position: absolute;
			left: 0;
			top: -53px;
		}
	}

	.iconShop {
		font-size: 18px;
		color: #583a0e;
	}

	// 模糊搜索
	.searchBox {
		border-bottom: 1px solid #3a416e;
	}

	.integral {
		width: calc(100% - 300px)
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.colorff0000 {
		color: #ff0000;
	}

	.output {
		/deep/.el-input__inner {
			height: 34px !important;
			border: 1px solid #2E4A8C !important;
			border-radius: 0;
			width: 300px;
		}
	}

	/deep/.el-input-group__append {
		@include themify($themes) {
			border: 1px solid #2E4A8C;
			background-color: #2E4A8C;
		}

		border-radius: 0;
	}

	.colorActive {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	//店铺
	.shopBox {
		background-image: linear-gradient(to right, #ffedd4, #feddb2);
	}

	.shopItem {
		width: 190px;
		height: 85px;
		color: #1e1e1e;
		border: 1px solid #f2f2f2;
		padding: 0 12px;
	}

	.shopItemImg {
		width: 55px;
		height: 55px;
		border-radius: 75px;
	}

	.shopItemTitle {
		width: 138px;
	}

	.text-el {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 138px;
		color: #A1A1A1;
	}

	.shopBoxBottom {
		width: 1100px;
		height: 40px;
		background-image: url("../../assets/shopBottom.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center top;
		text-align: center;
		line-height: 40px;
		color: #583a0e;
	}

	//可兑换商品
	.goodsTab-box {
		padding-left: 10px;
		padding-right: 10px;
		height: 35px;
		line-height: 35px;
		border: 2px solid #f2f2f2;
		// border-top: 1px solid #f2f2f2;
		// border-bottom: 1px solid #f2f2f2;
	}

	.textfon {
		font-weight: 700;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	/deep/.theme-themes_blue .pointer-color-theme:hover {
		color: #000000;
	}

	// 积分够列表
	.box-two-row-carousel ::v-deep {
		width: 1100px;

		.el-carousel__indicators {
			display: none;
		}
	}
    .box-four-item-box {
    	min-width: 1060px;
    	min-height: 315px;
    	height: auto;
    }
	.box-four-item {
		width: 210px;
		height: 290px;
		border: 1px solid #f2f2f2;
	}
    .box-four-picture-box {
    	width: 210px;
    	height: 210px;
    }
	.box-two-title {
		height: 40px;
		line-height: 20px;
	}
	.box-two-integral {
		width: 60%;
		margin-top: 4px;
		height: 20px;
		line-height: 20px;
	}
	.marginR {
		margin-right: 9px;
	}

	.box-two-item-box {
		width: 155px;
		height: 225px;
		background-color: #fff3db;
		margin-top: 10px;
	}

	.box-two-picture-box {
		width: 192px;
		height: 202px;
	}
	.box-one-row-buoy {
		min-width: 60px;
		width: 60px;
		height: 60px;
		// background-image: linear-gradient(to right, #ff0000, #ff9999);
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;
		padding: 0 10px;
		line-height: 24px;
	}

	.box-one-row-buoy1 {
		min-width: 60px;
		width: 60px;
		height: 60px;
		// background-image: linear-gradient(to right, #ff0000, #ff9999);
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 10;
		padding: 0 10px;
		line-height: 24px;
	}

	.box-one-row-buoy2 {
		min-width: 60px;
		width: 60px;
		height: 60px;
		// background-image: linear-gradient(to right, #ff0000, #ff9999);
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 10;
		padding: 0 10px;
		line-height: 24px;
	}

	.box-one-row-buoy3 {
		min-width: 60px;
		width: 60px;
		height: 60px;
		// background-image: linear-gradient(to right, #ff0000, #ff9999);
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		padding: 0 10px;
		line-height: 24px;
	}

	.box-two-picture {
		height: 130px;
	}

	

	

	// 积分购列表
	// 分页
	.pagination {
		margin-top: 20px;
		float: right;
	}

	.not-found-box {
		margin: 10px auto 0;
		background-color: #e0e7f5;
	}

	.not-found-innerbox {
		padding: 0 15px;
		line-height: 30px;
	}
</style>
